// 配置路由文件

import { LoginOutlined, LogoutOutlined, FieldNumberOutlined, PieChartOutlined, ContactsOutlined, DollarOutlined, AccountBookOutlined, UploadOutlined, SendOutlined, HistoryOutlined, SolutionOutlined, CheckCircleOutlined, UserOutlined, HomeOutlined, ScheduleOutlined, MoneyCollectOutlined, FileProtectOutlined, AppstoreOutlined, UserSwitchOutlined, TeamOutlined, GlobalOutlined, BarChartOutlined } from '@ant-design/icons';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    title: '预填箱记录',
    route: '/check',
    component: 'Check',
    icon: <HomeOutlined />,
    role: ['客户', '卡口统计'],
  },
  {
    title: '作业明细',
    route: '/work_detail',
    component: 'WorkDetail',
    icon: <HistoryOutlined />,
    role: ['卡口统计', '财务会计', '财务核算'],
  },
  {
    title: '库位分布图',
    route: '/location_map',
    component: 'LocationMap',
    icon: <PieChartOutlined />,
    role: ['卡口统计'],
  },
  {
    title: '提箱计划',
    route: '/out_yard_plan',
    component: 'OutYardPlan',
    icon: <SolutionOutlined />,
    role: ['卡口统计'],
  },
  {
    title: '预约集港',
    route: '/appoint',
    component: 'Appoint',
    icon: <ScheduleOutlined />,
    role: ['卡口统计'],
  },
  {
    title: '船名统计',
    route: '/statistics',
    component: 'Statistics',
    icon: <BarChartOutlined />,
    role: ['卡口统计'],
  },
  // {
  //     title: '修改密码',
  //     route: '/update_account',
  //     component: 'UpdateAccount',
  //     icon: <UserOutlined />,
  // },

  {
    title: '运抵高速',
    route: '/asgn',
    component: 'Asgn',
    icon: <SendOutlined />,
    role: ['卡口统计'],
    children: [
      {
        title: '批量发送运抵',
        route: '/asgn/add',
        component: 'AsgnAdd',
      },
      {
        title: '查看发送运抵记录',
        route: '/asgn/check',
        component: 'AsgnCheck',
      },
      {
        title: '全部高速上报',
        route: '/asgn/index',
        component: 'AsgnCheck',
      },
      {
        title: '出口出箱（手动）',
        route: '/asgn/delivery_export',
        component: 'AsgnCheck',
      },
      {
        title: '进口出箱（手动）',
        route: '/asgn/delivery_import',
        component: 'AsgnCheck',
      },
      {
        title: '出口收箱（手动）',
        route: '/asgn/receive_export',
        component: 'AsgnCheck',
      },
      {
        title: '进口收箱（手动）',
        route: '/asgn/receive_import',
        component: 'AsgnCheck',
      },
    ]
  },
  {
    title: '用户管理',
    route: '/user_client',
    component: 'UserClient',
    icon: <TeamOutlined />,
    role: ['卡口统计', '财务核算'],//.indexOf('财务') !== -1
  },
  {
    title: '船名管理',
    route: '/ship_set',
    component: 'UserClient',
    icon: <FieldNumberOutlined />,
    role: ['卡口统计'],
  },
  {
    title: '价格信息',
    route: '/user_price',
    component: 'UserClient',
    icon: <DollarOutlined />,
    role: ['卡口统计', '财务会计', '财务核算'],
  },
  {
    title: '资金流水',
    route: '/user_account',
    component: 'UserClient',
    icon: <AccountBookOutlined />,
    role: ['卡口统计', '财务会计', '财务核算'],
  },
  {
    title: '客户应收',
    route: '/client_will_receip',
    component: 'UserClient',
    icon: <LoginOutlined />,
    role: ['财务会计', '财务核算'],
  },
  {
    title: '客户应付',
    route: '/client_will_pay',
    component: 'UserClient',
    icon: <LogoutOutlined />,
    role: ['财务会计', '财务核算'],
  },
  {
    title: '库位管理',
    route: '/warehouse_manage',
    component: 'WarehouseManage',
    icon: <AppstoreOutlined />,
    role: ['卡口统计'],
    hidden: true,
  },
  {
    title: '司机及日报',
    route: '/car_and_driver',
    component: 'CarAndDriver',
    icon: <AppstoreOutlined />,
    role: ['卡口统计', '财务会计', '财务核算'],
  },

  {
    title: '打印',
    route: '/print',
    component: 'Print',
    icon: <SendOutlined />,
    role: ['卡口统计'],
    hidden: true,
    children: [
      {
        title: '打印联系单',
        route: '/print/contact_bill',
        component: 'ContactBill',
        para: 'no',
      },
    ]
  },
  {
    title: '重机作业',
    route: '/zhongji',
    component: 'Asgn',
    icon: <SendOutlined />,
    role: ['重机司机'],
    children: [
      {
        title: '重机落箱',
        route: '/zhongji/in_yard',
        component: 'AsgnAdd',
      },
      {
        title: '重机提箱',
        route: '/zhongji/out_yard',
        component: 'AsgnCheck',
      },
    ]
  },
]